module.exports = [{
      plugin: require('/Users/zizo/frontend/blog/gatsby-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-131052853-1"},
    },{
      plugin: require('/Users/zizo/frontend/blog/gatsby-blog/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/zizo/frontend/blog/gatsby-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
